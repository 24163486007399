import React, { createContext, useMemo, useState } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  ModalContent,
  Checkbox,
  Flex,
  Text,
} from '@chakra-ui/react';
import Loading from '@/containers/Loading';
import { useMutation, useQuery } from 'react-query';
import api from '@/api';
import toast from '@/helpers/toast';
import { useRouter } from 'next/router';
import { useTranslate } from '@/hooks/useTranslate';
import { closingCheck, storeOpeningCheck } from './utils';

interface StoreStatusProps {
  isOpen: boolean;
  isCompletedApp: boolean;
  openModal: () => void;
  openStore?: () => void;
}

const StoreContext = createContext<StoreStatusProps>({} as StoreStatusProps);

const StoreProvider: React.FC = ({ children }) => {
  const { onClose, onOpen, isOpen } = useDisclosure();
  const {
    data: status,
    isLoading,
    refetch,
  } = useQuery('api.company.get', api.company.get, {
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    refetchOnMount: true,
  });
  const modal = useDisclosure();
  const router = useRouter();

  const { mutateAsync, isLoading: isOpening } = useMutation(api.company.open);
  const { mutateAsync: mutateClose, isLoading: isClosing } = useMutation(api.company.close);
  const { t } = useTranslate();

  const [openChecks, setOpenChecks] = useState<number[]>([]);
  const [closeChecks, setCloseChecks] = useState<number[]>([]);

  const handleOpenCheck = (value: number) => {
    setOpenChecks((prev) =>
      prev.includes(value) ? prev.filter((check) => check !== value) : [...prev, value],
    );
  };

  const handleCloseCheck = (value: number) => {
    setCloseChecks((prev) =>
      prev.includes(value) ? prev.filter((check) => check !== value) : [...prev, value],
    );
  };

  const open = async () => {
    try {
      await mutateAsync();
      refetch();
      onClose();
      router.reload();
    } catch (err) {
      toast.error((err as Error).message);
    }
  };

  const close = async () => {
    try {
      await mutateClose();
      refetch();
      modal.onClose();
      router.reload();
    } catch (err) {
      toast.error((err as Error).message);
    }
  };

  const storeShift = useMemo(() => {
    if (status && status.shifts) {
      return `${t('todaysShift')}: ${(status?.shifts as any)[status?.today as number]?.first} ${t(
        'until',
      )} ${(status?.shifts as any)[status?.today as number]?.second}`;
    }
    return '';
  }, [status, t]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <StoreContext.Provider
      value={{
        isOpen: !!status?.opened,
        isCompletedApp: !!status?.completedApp,
        openModal: () => {
          if (status?.opened) {
            modal.onOpen();
          } else {
            onOpen();
          }
        },
        openStore: open,
      }}
    >
      <Modal {...modal} size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('header.closeStore.modal.title')}</ModalHeader>
          <ModalBody>
            <Text mb={4}>{t('header.closeStore.modal.description')}</Text>
            <Flex direction="column" align="start" gridGap={4}>
              {closingCheck.closing.map((check) => (
                <Checkbox
                  key={check.value}
                  isChecked={closeChecks.includes(check.value)}
                  onChange={() => handleCloseCheck(check.value)}
                >
                  {t(check?.label)}
                </Checkbox>
              ))}
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={modal.onClose} mr={3}>
              {t('goBack')}
            </Button>
            <Button
              colorScheme="red"
              onClick={close}
              isLoading={isClosing}
              disabled={closeChecks.length !== closingCheck.closing.length}
            >
              {t('closeStore')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal onClose={onClose} isOpen={isOpen} size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('header.openStore.modal.title')}</ModalHeader>
          <ModalBody>
            <Text mb={4}>
              {status?.able
                ? t('header.openStore.modal.description')
                : `${t('totemOrders.enabled')}
                ${t('store.outOfShift')}\n${storeShift}`}
            </Text>
            <Flex direction="column" align="start" gridGap={4}>
              {storeOpeningCheck.opening.map((check) => (
                <Checkbox
                  key={check.value}
                  isChecked={openChecks.includes(check.value)}
                  onChange={() => handleOpenCheck(check.value)}
                >
                  {t(check?.label)}
                </Checkbox>
              ))}
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              {t('close')}
            </Button>
            <Button
              colorScheme="green"
              ml={3}
              onClick={open}
              isLoading={isOpening}
              disabled={openChecks.length !== storeOpeningCheck.opening.length}
            >
              {t('openStore')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {children}
    </StoreContext.Provider>
  );
};

export { StoreProvider, StoreContext };
